<script setup>
import c3api from '@/c3api';
import PrintLabelsModal from '@/components/products/PrintLabelsModal.vue';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

const props = defineProps({
  product: {
    type: Object,
    required: false,
  },
});
const toast = useToast();

const navItems = (id) => {
  return [
    { text: "Details", icon: "mdi-file", to: `/products/${id}` },
    {
      text: "Inbound Activity",
      icon: "mdi-download-box",
      to: `/products/${id}/inbound-activity`,
    },
    {
      text: "Outbound Activity",
      icon: "mdi-upload-box",
      to: `/products/${id}/outbound-activity`,
    },
    {
      text: "Transactions",
      icon: "mdi-receipt-text",
      to: `/products/${id}/transactions`,
    },
    { 
      text: "Inventory", 
      icon: "mdi-tag-multiple",
      to: `/products/${id}/inventory` 
    },
    {
      text: "Lots",
      icon: "mdi-barcode",
      to: `/products/${id}/lots`,
    },
    {
      text: "Pallets",
      icon: "mdi-shipping-pallet",
      to: `/products/${id}/pallets`,
    },
    {
      text: "Product Images",
      icon: "mdi-file-image",
      to: `/products/${id}/product-images`,
    },
    {
      text: "Damage Reports",
      icon: "mdi-flash",
      to: `/products/${id}/damage-reports`,
    },
  ];
};
const printLabelModal = ref(false);

const printItems = (id) => {
  return [{ text: "Create New Labels", icon: "mdi-printer", click: () => { printLabelModal.value = true } }];
};

const handlePrintLabel = async ( createLabel, labelDetails) => {
  if(createLabel) {
    if(!labelDetails.expiry) {
      toast.error("Please select an expiration date.");
      return
    }

    try {
      const response = await c3api.post(`/products/${props.product?.id}/label_maker`, { data: labelDetails }, { responseType: 'blob' });
      const url = URL.createObjectURL(response.data)
      window.open(url, '_blank');
      printLabelModal.value = false;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    }
  } else {
    printLabelModal.value = false;
  }
};
</script>

<template>
  <div class="d-flex ml-4">
    <div class="w-25">
      <v-card class="mx-auto pa-2 w-100">
        <div class="text-center" v-if="!product">
          <v-progress-circular
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-list v-if="props.product">
          <v-list-subheader>PRODUCT - {{ product.id }}</v-list-subheader>

          <v-list-item
            exact
            v-if="product"
            v-for="(item, i) in navItems(product.id)"
            :key="i"
            :value="item"
            color="primary"
            :to="item.to"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card class="mx-auto pa-2 w-100 mt-4">
        <div class="text-center" v-if="!product">
          <v-progress-circular
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-list v-if="props.product">
          <v-list-subheader>PRINT</v-list-subheader>

          <v-list-item
            v-if="product"
            v-for="(item, i) in printItems(product.id)"
            :key="i"
            :value="item"
            color="primary"
            rounded="xl"
            @click="item.click ? item.click() : null"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
          <PrintLabelsModal v-if="printLabelModal" :callback="handlePrintLabel" />
        </v-list>
      </v-card>
    </div>
    <div class="w-100">
      <slot />
    </div>
  </div>
</template>

<style>
@media (max-width: 768px) {
  .v-card .mx-auto {
    max-width: 75px;
  }
}
</style>
