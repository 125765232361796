<script setup>
import { ref, reactive, watch } from "vue";

const props = defineProps({
    callback: Function
});

const labelDetails = reactive({
    pallet_no: 1,
    cases_per_pallet: 1,
    lot: '',
    expiry: null
})
const expiryDate = ref(new Date(Date.now()));
const dialog = ref(true);
const menu = ref(false)

watch(expiryDate, (newValue) => {
  if (newValue) {
    const date = new Date(newValue);
    labelDetails.expiry = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  }
});

</script>

<template>
    <v-dialog v-model="dialog"  max-width="700" persistent>
        <v-card rounded="lg">
        <v-card-title class="d-flex bg-surface-variant justify-space-between align-center">
            <div class="text-white text-h5 text-medium-emphasis ps-2">
                Print Labels
            </div>

            <v-btn
            icon="mdi-close"
            variant="text"
            @click="callback(false, labelDetails)"
            ></v-btn>
        </v-card-title>

        <v-divider class="mb-4" />

        <v-row class="px-6 py-2" align="center">
            <v-col cols="6">
                <label># of Pallets</label>
            </v-col>
            <v-col cols="6">
                <v-text-field
                  density="compact"
                  v-model="labelDetails.pallet_no"
                  variant="outlined"
                  hide-details="auto"
                  type="number"
                ></v-text-field>
            </v-col>

            <v-divider />

            <v-col cols="6">
                <label>Cases per Pallet</label>
            </v-col>
            <v-col cols="6">
                <v-text-field
                  density="compact"
                  v-model="labelDetails.cases_per_pallet"
                  variant="outlined"
                  hide-details="auto"
                  type="number"
                ></v-text-field>
            </v-col>

            <v-divider />

            <v-col cols="6">
                <label>Lot</label>
            </v-col>
            <v-col cols="6">
                <v-text-field
                  density="compact"
                  v-model="labelDetails.lot"
                  placeholder="Enter Lot..."
                  variant="outlined"
                  hide-details="auto"
                ></v-text-field>
            </v-col>

            <v-divider />

            <v-col cols="6">
                <label>Expiration</label>
            </v-col>
            <v-col cols="6">

                <v-text-field
                    density="compact"
                    hide-details="auto"
                    id="menu-activator"
                    v-model="labelDetails.expiry"
                    placeholder="yyyy/mm/dd"
                    variant="outlined"
                    readonly
                ></v-text-field>
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    activator="#menu-activator"
                >
                    <v-date-picker
                    update:modelValue="() => {}"
                    show-adjacent-months
                    v-model="expiryDate"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-divider class="mt-2" />

        <v-card-actions class="my-2 me-4 ga-2 d-flex justify-end">
            <v-btn
            class="text-none"
            text="Cancel"
            color="surface-light"
            variant="flat"
            @click="callback(false, labelDetails)"
            ></v-btn>

            <v-btn
            class="text-none"
            text="Create PDF"
            color="primary"
            variant="flat"
            @click="callback(true, labelDetails)"
            ></v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
    .text-white {
        color: white !important;
    }
</style>